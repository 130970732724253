<template>
  <div>
    <div class='main-loader' v-if='isLoading'></div>
    <div class="product-page">
      <b-container>
        <search-bar @search-input="generateImages" />
        <div v-if="prevImages.length">
          <b-row>
            <product-card 
              v-for="product in prevImages" 
              :key="product.id" 
              :product="product" 
              @toggle-like="updateLikeStatus" 
            />
          </b-row>
        </div>
        <div class="no-data mt-5 mb-5" v-else-if="initialLoadingComplete && !prevImages.length && !isLoading">
          <div class="mx-auto text-center">
            <h2>{{ noDataMessage }}</h2>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

  
<script>
import SearchBar from './searchBar.vue';
import ImageGallery from './ImageGallery.vue';
import productCard from './imageCard.vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import { EventBus } from '@/event-bus'; 
import { saveImagesToIndexedDB, getImagesFromIndexedDB } from '@/utils/indexedDB';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    SearchBar,
    ImageGallery,
    productCard,
  },
  data() {
    return {
      isLoading: false,
      prevImages: [],
      noDataMessage: 'Enter prompt to create funny memes and beautiful images!',
      initialLoadingComplete: false,
      guestRequestCount: parseInt(localStorage.getItem('guestRequestCount')) || 0, // Initialize from localStorage
    };
  },
  created() {
    this.$store.dispatch('app/syncUserFromStorage');
    EventBus.$on('generate-images', this.generateImages);
  },
  computed: {
    ...mapState('app', {
      Banner: state => state.banner,
      FeaturedImages: state => state.featured_images,
      ...mapState('images', ['images']),
    }),
    ...mapGetters('app', ['isLogged', 'userData']),
    userId() {
      return this.userData?.userId || null;
    },
  },
  watch: {
    isLogged(newValue) {
      if (newValue) {
        // Reset guest request count when user logs in
        this.guestRequestCount = 0;
        localStorage.removeItem('guestRequestCount');
        this.fetchImages();
      }
    },
  },
  mounted() {
    if (this.isLogged) {
      this.fetchImages();
    } else {
      this.initialLoadingComplete = true;
    }
    this.fetchFeaturedItems();
  },
  methods: {
    ...mapActions('app', [
      'fetchFeaturedItems',
      'loadFeaturedImagesFromStorage',
      'syncUserFromStorage',
    ]),

    async generateImages(prompt, imageStyle, imageType) {
      const baseEndpoint = 'https://api.briks.ai';
      const endpoint = imageType === 'memes' ? `${baseEndpoint}/web/memes` : `${baseEndpoint}/web/images`;
      const topic = prompt;
      const params = new URLSearchParams({ 
        prompt: prompt,
        topic: topic,
        imageStyle: imageStyle,
      }); 
      const url = `${endpoint}?${params.toString()}`;

      const userId = this.userData?.userId;
      const tenant = JSON.parse(localStorage.getItem('domain_Data'));
      const tenantId = tenant?.tenant_id;

      // Allow up to 3 requests for non-logged-in users
      if (!userId) {
        if (this.guestRequestCount < 3) {
          this.guestRequestCount += 1;
          localStorage.setItem('guestRequestCount', this.guestRequestCount);

          // Optionally inform the user
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `You have ${3 - this.guestRequestCount} free request(s) left. Please login to continue.`,
              icon: 'InfoIcon',
              variant: 'info',
            },
          });
        } else {
          this.$bvModal.show("modal-login"); 
          return;
        }
      }

      this.isLoading = true; // Start loading
      EventBus.$emit('update-generating', true); // Update generating status

      try {
        const response = await fetch(url, {
          headers: { 
            'Accept': 'text/event-stream',
          },
          redirect: 'follow',
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let imageUrls = [];

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          
          const chunk = decoder.decode(value);
          const events = chunk.split('\n\n').filter(Boolean);

          for (const event of events) {
            try {
              const data = JSON.parse(event.replace(/^data: /, ''));
              if (data.success) {
                let items = [];
                if (imageType === 'memes') {
                  items = data.meme ? [data.meme] : data.memes;
                } else {
                  items = data.image ? [data.image] : data.images;
                }

                imageUrls = items.map(item => {
                  const id = item.split('/').pop().split('.').shift(); // Extract ID and remove extension
                  const imageUrl = `${item}`;
                  return {
                    id: id,
                    image_url: imageUrl,
                    prompt: prompt,
                    like_or_dislike: 'N',
                  };
                });

                // Check for duplicates before adding
                for (const newImage of imageUrls) {
                  if (!this.prevImages.some(image => image.id === newImage.id)) {
                    this.prevImages.unshift(newImage); // Add new images at the top

                    // Save the new image to Cosmos DB if user is logged in
                    if (userId && tenantId) {
                      await this.saveImageToDB(
                        newImage.id,
                        userId,
                        tenantId,
                        newImage.image_url,
                        prompt,
                        imageStyle,
                        topic,
                        'user'
                      );
                    }
                  }
                }
                if (data.message === "completed") {
                  this.prevImages = [...this.prevImages];
                  await saveImagesToIndexedDB(this.prevImages);
                  this.isLoading = false;
                  EventBus.$emit('update-generating', false); // Update generating status
                }
              }
            } catch (error) {
              console.error('Error parsing SSE data:', error);
            }
          }
        }

        this.isLoading = false; // End loading

      } catch (error) {
        console.error('Error in generateImages:', error);
        this.isLoading = false;
        EventBus.$emit('update-generating', false);
      }
    },

    async saveImageToDB(id, userId, tenantId, imageUrl, prompt, imageStyle, topic, creation = 'user') {
      try {
        const response = await fetch(`${process.env.VUE_APP_BASE_URL}/saveImage`,{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id, userId, tenantId, imageUrl, prompt, imageStyle, topic, creation }),
        });

        const data = await response.json();
        console.log(data);
      } catch (error) {
        console.error('Error saving image to DB:', error);
      }
    },

    async fetchImages() {
      this.isLoading = true;
      try {
        // First, try to get images from IndexedDB
        const cachedImages = await getImagesFromIndexedDB();
        if (cachedImages.length > 0) {
          this.prevImages = cachedImages;
          this.initialLoadingComplete = true;
        }
        
        // If user is logged in, fetch images from API
        const userId = this.userData?.userId;
        
        if (userId) {
          const response = await fetch(`${process.env.VUE_APP_BASE_URL}/getImages?userId=${userId}`);
          const data = await response.json();
          
          if (Array.isArray(data.images)) {
            const processedImages = data.images.map(image => ({
              id: image.id,
              image_url: image.imageUrl,
              prompt: image.prompt,
              topic: image.topic,
              like_or_dislike: image.like_or_dislike,
              timestamp: image.timestamp,
            }));
            
            // Update local state with new images
            this.prevImages = processedImages;
            this.initialLoadingComplete = true;
            
            // Save new images to IndexedDB
            await saveImagesToIndexedDB(processedImages);
          } else {
            console.error('Expected an array of images, but got:', data.images);
          }
        }
      } catch (error) {
        console.error('Error fetching images:', error);
      } finally {
        this.isLoading = false;
      }
    },

    async updateLikeStatus(imageId, likeStatus) {
      const userId = this.userData?.userId;

      if (!userId) {
        this.$bvModal.show("modal-login");
        return;
      } 
      try {
        const response = await fetch(`${process.env.VUE_APP_BASE_URL}updateLikeStatus`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId, imageId, likeStatus }),
        });

        const data = await response.json();

        if (data.message === 'Like status updated successfully!') {
          // Update the local state to reflect the new like status
          const image = this.prevImages.find(img => img.id === imageId);
          if (image) {
            image.like_or_dislike = likeStatus;
          }
        }
      } catch (error) {
        console.error('Error updating like status:', error);
      }
    },

    // ... other methods if any ...
  },
};
</script>

  
  <style scoped>
  .search-input {
    border-radius: 0rem !important;
  }
  </style>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .swiper-slide {
    display: flex;
    align-items: 'auto';
    justify-content: center;
    padding: 2px !important;
    padding-bottom: 15px !important;
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}


.swiper-container {
    width: 100%;            /* Ensures the swiper takes full width */
    overflow-x: auto;       /* Enables horizontal scrolling for the container */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on touch devices */
    scrollbar-width: none;  /* Hides scrollbar in Firefox */
    -ms-overflow-style: none;  /* Hides scrollbar in IE and Edge */
}

.swiper-container::-webkit-scrollbar {
    display: none;  /* Hides scrollbar in WebKit browsers */
}

  </style>
  